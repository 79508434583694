import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"
import Grid from "@ecom/ui/components/Grid"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import clsx from "clsx"
import HalvaQuestion from "./img/halvaQuestion"
import Halva from "./img/halva"
import HalvaPurple from "./img/halvaShapka"

import * as styles from "./twoChoice.module.scss"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import HalvaCardBlue from "./img/halvaCardBlue"

type AboutHalvaProps = {
  orderNum?: string
  newYear?: boolean
  textBlockTitle?: ReactNode
  title?: ReactNode
  secondTextBlockTitle?: ReactNode
  variant?: "standart" | "shapka" | "kiberponedelnik" | "1500bonus"
  swapCard?: boolean
  swapCardText?: boolean
  swapBtnText?: boolean
}

const handleClick = () => {
  scrollToPersonalForm()

  pushToDataLayer({
    event: "buttonClick",
    name: "Оформить карту",
    placement: "third_screen_button_halva",
  })
}

const handleClickLink = () => {
  pushToDataLayer({
    event: "buttonClick",
    name: "Регистрация",
    placement: "third_screen_button_halva",
  })
}

const handleClickShapka = () => {
  const map = document.getElementById("map")
  if (map) {
    map.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  pushToDataLayer({
    event: "buttonClick",
    name: "Ждем тебя в «Шапке»! ",
    placement: "map_halva",
  })
}

const handleClickKiberponedelnik = () => {
  pushToDataLayer({
    event: "buttonClick",
    name: "twist",
    placement: "wheel_of_fortune",
  })
}

export function TwoChoice({
  orderNum,
  newYear = false,
  textBlockTitle = <>Есть «Халва» - регистрируйся в&nbsp;акции прямо сейчас!</>,
  secondTextBlockTitle = (
    <>Нет «Халвы» - оформляй карту и после получения не забудь зарегистрироваться в акции</>
  ),
  title = false,
  variant = "standart",
  swapCard = false,
  swapCardText = false,
  swapBtnText = false,
}: AboutHalvaProps) {
  const cardText = swapCardText ? secondTextBlockTitle : textBlockTitle
  const secondCardText = swapCardText ? textBlockTitle : secondTextBlockTitle
  function CtaLink() {
    if (newYear) {
      return (
        <Link
          href="https://halvapoly.halva.games/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnNY}
          underline="none"
          onClick={handleClickLink}
        >
          Играть
        </Link>
      )
    }
    if (variant === "shapka") {
      return (
        <Button className={styles.btnPurple} onClick={handleClickShapka}>
          Ждем тебя в «Шапке»!
        </Button>
      )
    }
    if (variant === "kiberponedelnik") {
      return (
        <Link
          href="https://postcard.sovcombank.ru/?utm_medium=Referral&utm_source=halvacard.ru&utm_campaign=order-pkw-kiberponedelnik"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btnBlue}
          underline="none"
          onClick={handleClickKiberponedelnik}
        >
          Крутить
        </Link>
      )
    }
    if (variant === "1500bonus") {
      return (
        <Link
          href="https://go.halvacard.ru/email/web_invite_friend_lim/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btn}
          underline="none"
          onClick={handleClickLink}
        >
          {swapBtnText ? "Оформить карту" : "Привести друга"}
        </Link>
      )
    }

    return (
      <Link
        href="https://halvacard.ru/shops/actions/otkryvaem-shubohranilishe"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.btn}
        underline="none"
        onClick={handleClickLink}
      >
        Регистрация
      </Link>
    )
  }

  const setImageVariant = () => {
    switch (variant) {
      case "shapka":
        return <HalvaPurple alt="bg image" className={styles.img} />

      case "standart":
        return <Halva alt="bg image" className={styles.img} />

      case "1500bonus":
        return <Halva alt="bg image" className={styles.img} />

      case "kiberponedelnik":
        return <HalvaCardBlue alt="bg image" className={styles.img} />
      default:
        return null
    }
  }

  return (
    <section
      data-exclude={orderNum}
      className={clsx(styles.section, styles[variant])}
      id="TwoChoice"
    >
      <Container className={styles.container}>
        {title && (
          <h2 className={clsx({ [styles.kiberponedelnik]: variant === "kiberponedelnik" })}>
            {title}
          </h2>
        )}
        <Grid container className={clsx(styles.gridContainer, swapCard && styles.swapCard)}>
          <Grid sm={12} md={6} className={clsx(styles.firstCard, styles[variant])}>
            <div className={styles.content}>
              <div className={styles.textBlock}>{cardText}</div>
              {CtaLink()}
            </div>
            {setImageVariant()}
          </Grid>
          <Grid sm={12} md={6} className={clsx(styles.secondCard, styles[variant])}>
            <div className={styles.content}>
              <div className={styles.textBlock}>{secondCardText}</div>
              <Button onClick={handleClick} className={styles.btn}>
                {swapBtnText ? "Привести друга" : "Оформить карту"}
              </Button>
            </div>
            <HalvaQuestion alt="bg image" className={styles.img} />
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}
