// extracted by mini-css-extract-plugin
export var btn = "twoChoice-module--btn--RJNi+";
export var btnBlue = "twoChoice-module--btnBlue--b7hVY";
export var btnNY = "twoChoice-module--btnNY--xjckv";
export var btnPurple = "twoChoice-module--btnPurple--tjUKy";
export var content = "twoChoice-module--content--cWDjC";
export var firstCard = "twoChoice-module--firstCard--ldcHL";
export var gridContainer = "twoChoice-module--gridContainer--iI6-h";
export var img = "twoChoice-module--img--mVeZa";
export var kiberponedelnik = "twoChoice-module--kiberponedelnik--1IdAJ";
export var secondCard = "twoChoice-module--secondCard--P151Z";
export var section = "twoChoice-module--section--2oh9+";
export var shapka = "twoChoice-module--shapka--DZDBr";
export var swapCard = "twoChoice-module--swapCard--4CNeZ";
export var textBlock = "twoChoice-module--textBlock--T+C9a";